.lightBackground {
    background-image: 
    url(
  "/src/images/lightBackground.png");
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;
    font-size:50px;
    background-repeat:no-repeat; 
  }

  .darkBackground {
    background-image: 
    url(
  "/src/images/darkBackground.png");
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;
    font-size:50px;
    background-repeat:no-repeat; 
  }