@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
    width: 10px;
}
 

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}
 
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media screen and (max-width: 768px){
    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
  }